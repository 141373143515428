function slider() {
  var swiper = new Swiper(".mySwiper", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });
}

// ABOUT-PAGE-NAVIGATION-JS
function aboutpage() {
  setTimeout(function () {
    document.getElementById("about_page").scrollIntoView({
      behavior: "smooth",
    });
  }, 500);
}
function youtubeVideo() {
  var player = new MediaElementPlayer("#player1");
}
function notAllzeros() {
  $("input#Amount").keyup(function (e) {
    if ((this.value + "").match(/^0/)) {
      this.value = (this.value + "").replace(/^0+/g, "");
    }
    console.log("value", this.value);
  });
}
function onlynumbers() {
  $("#Amount").keypress(function (event) {
    console.log(event.which);
    if (event.which != 8 && isNaN(String.fromCharCode(event.which))) {
      event.preventDefault();
    }
  });
  // $("#Amount").keyup(function () {
  //   this.value = this.value.replace(/[^0-9.]/g, "");
  // });
}
